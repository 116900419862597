<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <!-- <v-divider class="mb-1" /> -->

    <v-list
      dense
      nav
    >
      <v-list-item>
        <v-list-item-avatar
          class="align-self-center"
          color="white"
          contain
        >
          <v-img
            src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png"
            max-height="30"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            class="text-h4"
            style="font-family: 'ceraProRegular', sans-serif !important;"
            v-text="profile.title"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
    <v-expansion-panels>
      <v-expansion-panel style="background: transparent;">
        <v-expansion-panel-header style="background-color: transparent; font-family: 'ceraProRegular', sans-serif !important;">
          <v-icon style="max-width: 50px;">
            mdi-view-list
          </v-icon>
          Procesos no Tributarios
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list
            expand
            nav
          >
            <div />

            <template v-for="(item, i) in computedGeneralItems">
              <base-item-group
                v-if="item.children"
                :key="`group-${i}`"
                :item="item"
              />

              <base-item
                v-else
                :key="`item-${i}`"
                :item="item"
              />
            </template>

            <div />
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <template v-slot:append>
      <v-btn
        class="ml-2"
        min-width="0"
        text=""
        style="background-color: transparent; width: 100%; font-family: 'ceraProRegular', sans-serif !important;"
        @click="openLink('https://wa.me/+593984530998')"
      >
        Cel: 0984530998 - 0990793760
        <v-icon
          style="padding-left: 10px;"
          color="white"
        >
          mdi-whatsapp
        </v-icon>
      </v-btn>
    </template>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      cartotradesa: false,
      restaurant: false,
      counter: false,
      computedItems: [],
      computedGeneralItems: [],
      items: [
        // {
        //   icon: 'mdi-view-dashboard',
        //   title: 'dashboard',
        //   to: '/dashboard',
        // },
        {
          title: 'Estadístico',
          icon: 'mdi-chart-line',
          to: '/pages/statisticsView',
        },
        {
          icon: 'mdi-folder',
          title: 'Perfil de Empresa',
          to: '/pages/user',
        },
        {
          icon: 'mdi-certificate',
          title: 'Certificación Electrónica',
          to: '/pages/certification',
        },
        {
          title: 'Usuarios',
          icon: 'mdi-account-check',
          to: '/pages/usersprofiles',
        },
        {
          title: 'Clientes',
          icon: 'mdi-account-multiple',
          to: '/pages/clients',
        },
        {
          title: 'Proveedores',
          icon: 'mdi-account-multiple-plus',
          to: '/pages/proveedores',
        },
        {
          title: 'Productos - Inventario',
          icon: 'mdi-view-list',
          to: '/pages/inventorydata',
        },
        {
          title: 'Facturación',
          icon: 'mdi-file-document',
          to: '/pages/billing',
        },
      ],
      itemsRestaurant: [
        // {
        //   icon: 'mdi-view-dashboard',
        //   title: 'dashboard',
        //   to: '/dashboard',
        // },
        {
          title: 'Estadístico',
          icon: 'mdi-chart-line',
          to: '/pages/statisticsView',
        },
        {
          icon: 'mdi-folder',
          title: 'Perfil de Empresa',
          to: '/pages/user',
        },
        {
          icon: 'mdi-certificate',
          title: 'Certificación Electrónica',
          to: '/pages/certification',
        },
        {
          title: 'Usuarios',
          icon: 'mdi-account-check',
          to: '/pages/usersprofiles',
        },
        {
          title: 'Clientes',
          icon: 'mdi-account-multiple',
          to: '/pages/clients',
        },
        {
          title: 'Proveedores',
          icon: 'mdi-account-multiple-plus',
          to: '/pages/proveedores',
        },
        {
          title: 'Productos - Inventario',
          icon: 'mdi-view-list',
          to: '/pages/inventorydata',
        },
        {
          title: 'Menú - Combos',
          icon: 'mdi-food',
          to: '/pages/menucombo',
        },
        {
          title: 'Facturación',
          icon: 'mdi-file-document',
          to: '/pages/billing',
        },
      ],
      itemsCartotradesa: [
        {
          title: 'Estadístico',
          icon: 'mdi-chart-line',
          to: '/pages/statisticsView',
        },
        {
          icon: 'mdi-folder',
          title: 'Perfil de Empresa',
          to: '/pages/user',
        },
        {
          icon: 'mdi-certificate',
          title: 'Certificación Electrónica',
          to: '/pages/certification',
        },
        {
          title: 'Usuarios',
          icon: 'mdi-account-check',
          to: '/pages/usersprofiles',
        },
        {
          title: 'Clientes',
          icon: 'mdi-account-multiple',
          to: '/pages/clients',
        },
        {
          title: 'Proveedores',
          icon: 'mdi-account-multiple-plus',
          to: '/pages/proveedores-internal-external',
        },
        {
          title: 'Agenda de Citas',
          icon: 'mdi-calendar-multiple-check',
          to: '/pages/scheduleappointments',
        },
        {
          title: 'Ingreso de Guías',
          icon: 'mdi-clipboard-plus',
          to: '/pages/entryguides',
        },
        {
          title: 'Productos - Inventario',
          icon: 'mdi-view-list',
          to: '/pages/inventorydata',
        },
        {
          title: 'Facturación',
          icon: 'mdi-file-document',
          to: '/pages/billing',
        },
        {
          title: 'Anticipos',
          icon: 'mdi-cash-multiple',
          to: '/pages/advance',
        },
        {
          title: 'Recibos',
          icon: 'mdi-clipboard-arrow-down',
          to: '/pages/receipts',
        },
        {
          title: 'Caja Chica',
          icon: 'mdi-cash',
          to: '/pages/pettycash',
        },
      ],
      itemsFacturacion: [
        {
          title: 'Productos - Inventario',
          icon: 'mdi-view-list',
          to: '/pages/inventorydata',
        },
        {
          title: 'Facturación',
          icon: 'mdi-file-document',
          to: '/pages/billing',
        },
      ],
      expandMenuItems: [
        {
          title: 'IVA - Retenciones',
          icon: 'mdi-newspaper',
          to: '/pages/ivaretenciones',
        },
        {
          title: 'Proformas',
          icon: 'mdi-clipboard-account',
          to: '/pages/proforma',
        },
        {
          title: 'Notas Venta - Compra',
          icon: 'mdi-clipboard-text',
          to: '/pages/notes',
        },
      ],
      expandMenuItemsCounter: [
        // {
        //   title: 'Herramientas',
        //   icon: 'mdi-account-key',
        //   to: '/pages/tools',
        // },
        {
          title: 'IVA - Retenciones',
          icon: 'mdi-newspaper',
          to: '/pages/ivaretenciones',
        },
        {
          title: 'Proformas',
          icon: 'mdi-clipboard-account',
          to: '/pages/proforma',
        },
        {
          title: 'Notas Venta - Compra',
          icon: 'mdi-clipboard-text',
          to: '/pages/notes',
        },
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      profile () {
        return {
          avatar: true,
          title: this.$store.state.profile.profileLoadedFirst ? this.$store.state.profile.profileLoadedFirst.email : '',
        }
      },
      profileLoaded () {
        return this.$store.state.profile.profileLoaded
      },
    },

    watch: {
      profileLoaded () {
        this.restaurant = this.profileLoaded.restaurant
        if (this.restaurant) {
          if (this.profileLoaded.comanda) {
            let banPush = true
            for (let i = 0; i < this.itemsRestaurant.length; i++) {
              if (this.itemsRestaurant[i].to === '/pages/opentable') {
                banPush = false
              }
            }
            if (banPush) {
              this.itemsRestaurant.push(
                {
                  title: 'Mesas Abiertas',
                  icon: 'mdi-table',
                  to: '/pages/opentable',
                },
              )
            }
          } else {
            if (this.itemsRestaurant[this.itemsRestaurant.length - 1].to === '/pages/opentable') {
              this.itemsRestaurant.pop()
            }
          }
          this.computedItems = this.itemsRestaurant.map(this.mapItem)
        } else {
          this.computedItems = this.items.map(this.mapItem)
        }

        if (this.$store.state.profile.profileType) {
          if (this.$store.state.profile.profileType.code.toString() === '002') {
            if (this.profileLoaded.comanda) {
              let banPush = true
              for (let i = 0; i < this.itemsFacturacion.length; i++) {
                if (this.itemsFacturacion[i].to === '/pages/opentable') {
                  banPush = false
                }
              }
              if (banPush) {
                this.itemsFacturacion.push(
                  {
                    title: 'Mesas Abiertas',
                    icon: 'mdi-table',
                    to: '/pages/opentable',
                  },
                )
              }
            } else {
              if (this.itemsFacturacion[this.itemsFacturacion.length - 1].to === '/pages/opentable') {
                this.itemsFacturacion.pop()
              }
            }
            this.computedItems = this.itemsFacturacion.map(this.mapItem)
          }
        }

        this.cartotradesa = this.profileLoaded.cartotradesa
        if (this.cartotradesa) {
          this.computedItems = this.itemsCartotradesa.map(this.mapItem)
        }

        this.counter = this.profileLoaded.counter
        if (this.counter) {
          this.computedGeneralItems = this.expandMenuItemsCounter.map(this.mapItem)
        } else {
          this.computedGeneralItems = this.expandMenuItems.map(this.mapItem)
        }
      },
    },

    methods: {
      openLink (link) {
        window.open(`${link}`, '_blank')
      },
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
    },
  }
</script>
<style lang="css">
@font-face {
  font-family: "ceraProBlack";
  src: url('~@/fonts/Cera_Pro/Cera-Pro-Black.otf');
  font-display: swap;
}
@font-face {
  font-family: "ceraProRegular";
  src: url('~@/fonts/Cera_Pro/Cera-Pro-Regular.otf');
  font-display: swap;
}
@font-face {
  font-family: "ceraProMedium";
  src: url('~@/fonts/Cera_Pro/Cera-Pro-Medium.otf');
  font-display: swap;
}
@font-face {
  font-family: "ceraProBold";
  src: url('~@/fonts/Cera_Pro/Cera-Pro-Bold.otf');
  font-display: swap;
}
body {
  font-family: 'ceraProBlack', sans-serif !important;
}
.v-navigation-drawer .v-list-item__content{
  font-family: 'ceraProRegular', sans-serif !important;
}
  .v-expansion-panel-content__wrap {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    max-width: 100%;
    padding: 0px 0px 0px 16px;
  }
  .v-navigation-drawer .v-list:not(.v-select-list) {
    background: inherit;
    padding: 0px;
  }
</style>
<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
